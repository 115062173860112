import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import unauthorised from '../../../../assets/403.svg'

function UnAuthorised() {
  return (
    <Stack justifyContent={'center'} alignItems="center" height="100vh">
      <img src={unauthorised} height={'400'} width={'500'} />

      <Typography>You are not authorized to view this page</Typography>
      <Button onClick={() => history.replace("/login")}>Login</Button>
    </Stack>
  )
}

export default UnAuthorised
